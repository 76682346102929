/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    hr: "hr",
    ul: "ul",
    li: "li",
    code: "code",
    p: "p",
    ol: "ol",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components), {Phase, CMInformation, Skill, Condition, Specialization, Boon, Trait, Boss, Instability, InformationBlock, Effect, IdealRotation, Item} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!CMInformation) _missingMdxReference("CMInformation", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!IdealRotation) _missingMdxReference("IdealRotation", true);
  if (!InformationBlock) _missingMdxReference("InformationBlock", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Phase) _missingMdxReference("Phase", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  return React.createElement(React.Fragment, null, React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "General Tips"
  }, React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Learn your precast timing for ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " and ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ". These skills CC hard and do massive burst damage."), "\n", React.createElement(_components.li, null, "Make sure to chain your skills properly while finishing your auto chains."), "\n", React.createElement(_components.li, null, "Link ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " after ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and use your cooldowns properly to not over damage  phases."), "\n", React.createElement(_components.li, null, "If your party is lacking ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application, be aware that applying ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " is up to you and the ", React.createElement(Specialization, {
    name: "Renegade"
  }), ". Do not cast ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), " or ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " late into the phases if you're running comps with a low ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " ramp-up."), "\n", React.createElement(_components.li, null, "Always precast ", React.createElement(Boon, {
    name: "Stability"
  }), " (via ", React.createElement(Skill, {
    name: "Defense Field",
    id: "30027"
  }), ", toolbelt of ", React.createElement(Skill, {
    name: "Bulwark Gyro",
    id: "30101"
  }), ") and ", React.createElement(Skill, {
    name: "superspeed",
    id: "5973"
  }), " whenever possible to profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."), "\n"))), "\n", React.createElement(Boss, {
    name: "mama",
    video: "xEHSN4uoyaM",
    videoCreator: "Jetrell",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "29921",
    eliteId: "6183",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    video: "xEHSN4uoyaM",
    videoCreator: "Jetrell",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "29921",
    eliteId: "6183",
    weapon1MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and be careful to time ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " into ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " for when the boss becomes vulnerable.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Skill swap ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " for ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " after the cast."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Right before mama becomes vulnerable precast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "In ", React.createElement(Effect, {
    name: "exposed"
  }), " burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), ".", React.createElement("br")), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " / ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " / ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), " and combo it with ", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " as fast as possible.", React.createElement("br"), "\r\nFinish it by casting ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Effect, {
    name: "Exposed"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " by precasting ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " whenever possible followed by  ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " into ", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Avoid going inside the protective dome if the spawn is too far away.", React.createElement("br"), " Instead, heal up using ", React.createElement(Skill, {
    name: "supplycrate",
    id: "5868"
  }), " blast into ", React.createElement(Skill, {
    name: "reconstructionfield",
    id: "29505"
  }), " on knight 2."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " / ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  })), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "supplycrate",
    id: "5868"
  }), " to instantly break the knight.", React.createElement("br"), " Use ", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), " to apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and auto attack in ", React.createElement(Effect, {
    name: "Exposed"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "supplycrate",
    id: "5868"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "positivestrike",
    id: "30501"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "negativebash",
    id: "29785"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "equalizingblow",
    id: "30489"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " to share  ", React.createElement(Boon, {
    name: "Quickness"
  }), " and deal moderate damage.", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " followed by ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "  then ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Keep dealing damage using ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " until the phase is done."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), " and combo it with ", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " as fast as possible.", React.createElement("br"), "\r\nFinish it by casting ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Effect, {
    name: "Exposed"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " to share  ", React.createElement(Boon, {
    name: "Quickness"
  }), " and deal moderate damage.", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " followed by ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "  then ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " into ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Finally use any available skills to finish the boss.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "siax",
    video: "cN_fLBIaAhA",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "29921",
    eliteId: "30800",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Frenzy",
    weapon1MainSigil2: "Serpentslaying",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    video: "cN_fLBIaAhA",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "29921",
    eliteId: "30800",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "82876",
    weapon1MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n", React.createElement(_components.li, null, "If your party lacks damage it is recommended to replace ", React.createElement(Item, {
    name: "frenzy",
    id: "82876"
  }), " with ", React.createElement(Item, {
    name: "impact",
    id: "24868"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Remember to swap ", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), " for ", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), "."), "\n")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and be careful to time ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " into ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " for when the boss becomes vulnerable.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Skill swap ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " for ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " after the cast."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and burst with the usual combo ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible.", React.createElement("br")), "\n"), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " at the end of the phase via ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), " then prepare to kill your add in split.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " / ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "While running to your add, precast ", React.createElement(Skill, {
    name: "poisongasshell",
    id: "30885"
  }), ", ", React.createElement(Skill, {
    name: "endothermicshell",
    id: "30307"
  }), " and ", React.createElement(Skill, {
    name: "flashshell",
    id: "30121"
  }), ".", React.createElement("br"), "\r\nFinish it with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use a portal to get to your add faster instead of walking."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongasshell",
    id: "30885"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "endothermicshell",
    id: "30307"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "flashshell",
    id: "30121"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " with ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " and ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " into ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Cast another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and if needed ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), ".", React.createElement("br"), "\r\nPrepare for split.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "While running to your add, precast ", React.createElement(Skill, {
    name: "poisongasshell",
    id: "30885"
  }), ", ", React.createElement(Skill, {
    name: "endothermicshell",
    id: "30307"
  }), " and ", React.createElement(Skill, {
    name: "flashshell",
    id: "30121"
  }), ".", React.createElement("br"), "\r\nFinish it with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use a portal to get to your add faster instead of walking."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongasshell",
    id: "30885"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "endothermicshell",
    id: "30307"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "flashshell",
    id: "30121"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " with ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), " and cast ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), ", ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Keep dealing damage with ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), " until the boss dies.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "kTTD3RrMIlI",
    videoCreator: "Elu",
    foodId: "91805",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "6183",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Impact",
    weapon1MainSigil2: "Serpentslaying",
    weapon1MainInfusion1Id: "37131",
    video: "kTTD3RrMIlI",
    videoCreator: "Elu",
    foodId: "91805",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "6183",
    weapon1MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24868",
    weapon1MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n", React.createElement(_components.li, null, "Make sure a ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " takes ", React.createElement(Skill, {
    name: "moastance",
    id: "45970"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and be careful to time ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " into ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " for when the boss becomes vulnerable (very important CC contribution)."), React.createElement("br"), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " and ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " to instant break the boss.", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), " and ", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), " under ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), " then continue dealing damage following a classic golem rotation.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "..."), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), " and ", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Share the boons as fast as possible after your team regroups from splits."), "\n"), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " before the boss becomes vulnerable.", React.createElement("br"), "\r\nUse ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " then prepare to break the bar.", React.createElement("br"), React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and ", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), " then share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), " and ", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nOnce under ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), " effect, it's time to ", React.createElement(Skill, {
    name: "detonate",
    profession: "engineer",
    id: "6162"
  }), " into ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), ", ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and follow it up with ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ", ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), " and another set of ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " if needed.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "l-BeKbgeL8s",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "9443",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30815",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    video: "l-BeKbgeL8s",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "9443",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30815",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and be careful to time ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " into ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " for when the boss becomes vulnerable.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), ".", React.createElement("br"))), React.createElement(InformationBlock, {
    title: "Important"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You are expected to pull the boss in the middle of casting ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "."), "\n")), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take Mistlock Singularity"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and ", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), " to break the bar.", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ", ", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), " and ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " on your way to Island 1 with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " (pull boss)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ol, {
    start: "10"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Splits"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "During splits you will have to use skills that give ", React.createElement(Effect, {
    name: "Superspeed"
  }), " on each Island.", React.createElement("br"), "\r\nDon't be late as you are expected to provide the necessary before your team starts running."), React.createElement(InformationBlock, {
    title: "Island 1"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and ", React.createElement(Effect, {
    name: "Stealth"
  }), " your party using ", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), " on your way to Island 2."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use ", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " to leap into the air and save animation time."), "\n")), React.createElement(InformationBlock, {
    title: "Island 2"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), ".", React.createElement("br"), "\r\nApply instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " with ", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), " and finish the add with ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " while moving to Island 3.")), React.createElement(InformationBlock, {
    title: "Island 3"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use ", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " to leap into the air and save animation time."), "\n")), React.createElement(InformationBlock, {
    title: "Island 4"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Hold your ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " for when the add is almost dead.", React.createElement("br"), "You need to provide ", React.createElement(Boon, {
    name: "Might"
  }), " to your party as soon as the phase starts."), "\n")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In the second split, take portals and make sure ", React.createElement(Boon, {
    name: "Quickness"
  }), " stays up then keep your ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " for Island 4 as usual."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 1")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " (leap)"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 2")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 3")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " (leap)"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 4")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " into ", React.createElement(Effect, {
    name: "Exposed"
  }), ".", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), " and ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Cast another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " and share ", React.createElement(Boon, {
    name: "Quickness"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "qhCSGO0cZ0o",
    timestamp: "139",
    videoCreator: "Hyp",
    foodId: "91805",
    utilityId: "9443",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30800",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Severance",
    weapon1MainInfusion1Id: "37131",
    video: "qhCSGO0cZ0o",
    videoCreator: "Hyp",
    foodId: "91805",
    utilityId: "9443",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30800",
    weapon1MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "84505"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), ".", React.createElement("br"), "\r\nBe careful to time ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " and ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " for when the boss becomes vulnerable.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Start your precast when the ball hits then template swap and cast ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), ".\r\nThe animation should finish right when the boss becomes vulnerable."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and move behind the boss while doing ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), ".", React.createElement("br"), "\r\nKeep dealing damage using ", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ".", React.createElement("br"), "\r\nUse another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " until the boss is phased."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your party lacks damage, keep ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " to reflect the orbs at the corner."), "\n")), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ol, {
    start: "11"
  }, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " (reflect)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Splits"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "You will be expected to contribute CC on the middle add for both splits."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Using ", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), " and ", React.createElement(Skill, {
    name: "grenadekit",
    id: "5805"
  }), " is usually enough."), "\n"), React.createElement(_components.p, null, "If needed, you can also help one of your ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " with ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), " and ", React.createElement(Skill, {
    name: "flashgrenade",
    id: "5808"
  }), "."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Split 1", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "flashgrenade",
    id: "5808"
  }), "\n"), "\n"), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Split 2"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), "\n", React.createElement(_components.ol, {
    start: "4"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Endothermic Shell",
    id: "30307"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Flash Shell",
    id: "30121"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "flashgrenade",
    id: "5808"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Effect, {
    name: "Superspeed"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), " and ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When the boss becomes green start casting ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "."), "\n"), React.createElement(_components.p, null, "Burst the phase with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " into ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), " and ", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nAvoid the knockback using ", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), " or simply dodge."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Dodging refresh ", React.createElement(Trait, {
    name: "explosiveentrance",
    id: "432"
  }), " which is a small damage increase."), "\n", React.createElement(_components.li, null, "If your party lacks ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application it is better to ", React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), " instead."), "\n"), React.createElement(InformationBlock, {
    title: "Important"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Land your first ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " as soon as possible or you will miss the second one."), "\n", React.createElement(_components.li, null, "Make sure to be fast sharing the necessary boons as if your ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " starts casting ", React.createElement(Skill, {
    name: "barrage",
    id: "12469"
  }), " without ", React.createElement(Boon, {
    name: "Quickness"
  }), ", it will ruin the whole phase."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "shockshield",
    id: "29840"
  }), " (or dodge)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "uaC9y-1G6Us",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30800",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    video: "uaC9y-1G6Us",
    videoCreator: "Hyp",
    foodId: "43360",
    utilityId: "50082",
    healId: "30357",
    utility1Id: "6020",
    utility2Id: "6161",
    utility3Id: "31248",
    eliteId: "30800",
    weapon1MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present use ", React.createElement(Skill, {
    name: "Throw Mine",
    id: "6161"
  }), " to boon strip."), "\n", React.createElement(_components.li, null, "In a faster party replace ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), " with ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "General Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always use your ", React.createElement(Skill, {
    name: "elitemortarkit",
    id: "30800"
  }), " in between phases, by applying conditions you will profit from ", React.createElement(Trait, {
    name: "modifiedammunition",
    id: "526"
  }), "."), "\n", React.createElement(_components.li, null, "You can double cast ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), "."), "\n", React.createElement(_components.li, null, "On ", React.createElement(Instability, {
    name: "Outflanked"
  }), ", save skills on Archdiviner and Gladiator."), "\n"), React.createElement(InformationBlock, {
    title: "How-to-Push"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cast a water field in the middle of the arena using ", React.createElement(Skill, {
    name: "elixirshell",
    id: "30032"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "reconstructionfield",
    id: "29505"
  }), " to blast your own water field with ", React.createElement(Skill, {
    name: "rocketcharge",
    id: "30665"
  }), " while you are pushing the orb. This allows you to sustain the damage from the electric field."), "\n", React.createElement(_components.li, null, "Use the Special Action Key (SAK) to come back and blast your ", React.createElement(Skill, {
    name: "elixirshell",
    id: "30032"
  }), "  from step 1."), "\n")))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ", share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " casting all other available wells.")), React.createElement(InformationBlock, {
    title: "On Platform"
  }, React.createElement(_components.p, null, "Precast your ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " and ", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), " beforing taking portal.")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Be careful to time ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " and ", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), " for when the boss becomes vulnerable."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "At the end of the cast swap ", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " with ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "."), "\n"), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to gain ", React.createElement(Boon, {
    name: "Stability"
  }), " and profit from ", React.createElement(Trait, {
    name: "objectinmotion",
    id: "1860"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bypasscoating",
    id: "29665"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sneakgyro",
    id: "30815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100 to 80%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), ", ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can help cleaving the anomaly using ", React.createElement(Skill, {
    name: "throwmine",
    profession: "engineer",
    id: "6161"
  }), "."), "\n"), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), " with ", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bigolbomb",
    id: "5813"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "shreddergyro",
    id: "29921"
  }), " / ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  })), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "functiongyro",
    id: "56920"
  }), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ol, {
    start: "9"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 (80 to 70%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Effect, {
    name: "Superspeed"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), " and ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Archdiviner"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), " and burst the add with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3 (70 to 50%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Effect, {
    name: "Superspeed"
  }), "  using ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), ", ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), ", ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonateminefield",
    id: "6166"
  }), " and ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nCast another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " if needed."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4 (50 to 40%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Effect, {
    name: "Superspeed"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), " and ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), " and ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Gladiator"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Like on Archdiviner, precast ", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), " and burst the add with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), ", ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " and ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ".", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sparecapacitor",
    id: "31167"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 5 (40 to 30%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "In ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Cast another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " if needed.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 6 (30 to 0%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Effect, {
    name: "Superspeed"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " using ", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), " and ", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), ", ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), ", ", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), ", ", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), " and ", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "throwmine",
    id: "6161"
  }), " and ", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), " into ", React.createElement(Skill, {
    name: "detonate",
    id: "6162"
  }), " whenever possible."), "\n"), React.createElement(_components.p, null, "Cast another ", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), " into ", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), " if needed.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "medicgyro",
    id: "30357"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "blastgyro",
    id: "31248"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "thunderclap",
    profession: "engineer",
    id: "30713"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "minefield",
    id: "6164"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "poisongrenade",
    id: "5806"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "freezegrenade",
    id: "5809"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "grenadebarrage",
    id: "5810"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "electrowhirl",
    id: "30088"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shrapnelgrenade",
    id: "5807"
  }), "\n"), "\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
